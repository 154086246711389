import store from '@/store'
import { ref, watch } from '@vue/composition-api'

const loading = ref(false)
const marResponse = ref([])
const commitResponse = ref([])
const userResponse = ref([])
const errorMessages = ref([])

const marTableHeaders = [
  { text: 'FIRST NAME', value: 'firstName' },
  { text: 'LAST NAME', value: 'lastName' },
  { text: 'POD', value: 'pod' },
  { text: 'CELL', value: 'cell' },
]

export default function useMARList() {
  // fetch MAR Data
  const fetchMARData = (medTime, userId, siteId, month) => {
    const payload = ref({})
    payload.value.medTime = medTime
    payload.value.userId = userId
    payload.value.siteId = siteId
    payload.value.month = month
    store
      .dispatch('app-mar/getMARData', payload.value)
      .then(response => {
        marResponse.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    fetchMARData()
  })

  const fetchMARWeek = (medTime, month) => {
    const payload = ref({})
    payload.value.medTime = medTime
    payload.value.month = month

    store
      .dispatch('app-mar/getMARWeek', payload.value)
      .then(response => {
        marResponse.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    fetchMARWeek()
  })

  const postMARDistribution = marDistributionDTO => {
    store
      .dispatch('app-mar/postMARDistribution', marDistributionDTO)
      .then(response => {
        commitResponse.value = response

        loading.value = false
      })
      .catch(error => {
        // TODO: Next Update - Show notification
        console.error('Oops, Unable to fetch!')

        errorMessages.value = error.response.data.error
      })
  }

  watch([], () => {
    postMARDistribution()
  })

  return {
    loading,
    errorMessages,

    userResponse,

    marResponse,
    fetchMARData,

    fetchMARWeek,

    postMARDistribution,
    commitResponse,

    marTableHeaders,
  }
}
