<template>
  <div>
    <v-container>
      <v-card>
        <div>
          <v-row>
            <v-col
              lg="3"
              sm="6"
            >
              <v-checkbox
                v-model="allTimes"
                :label="`All Med Pass Times`"
                @click="updateTime(null)"
              ></v-checkbox>
            </v-col>
            <v-col
              lg="3"
              xs="6"
            >
              <v-checkbox
                v-model="month"
                label="One Month"
                @change="!month"
              ></v-checkbox>
            </v-col>
          </v-row>

          <med-time-select
            v-if="!allTimes"
            v-model="medTime"
            :is-edit="true"
            label="Med Pass Time"
            :multiple="false"
            @med-time-select="updateTime"
          ></med-time-select>
        </div>

        <v-btn
          color="primary"
          @click="loadOfflineMAR"
        >
          <span> Distribute </span>
        </v-btn>
      </v-card>
    </v-container>
    <div
      v-if="dialog"
    >
      <m-a-r-view
        :mar-data="marResponse"
        :med-time="medTime"
        :month="month"
        :is-visible="dialog"
        @update-dialog="updateDialog"
      >
      </m-a-r-view>
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import MedTimeSelect from '@/components/medication/MedTimeSelect.vue'
import marService from '@/services/MARService'
import useMARService from '@/store/useMARService'
import MARView from './MARView.vue'

export default {
  components: {
    MedTimeSelect,
    MARView,
  },
  setup() {
    const MAR_APP = 'app-mar'

    // Register module
    if (!store.hasModule(MAR_APP)) {
      store.registerModule(MAR_APP, marService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MAR_APP)) store.unregisterModule(MAR_APP)
    })

    const { fetchMARWeek, userResponse, marResponse } = useMARService()
    const medTime = ref({})
    const allTimes = ref(false)
    const dialog = ref(false)
    const month = ref(false)

    return {
      fetchMARWeek,
      userResponse,
      marResponse,

      medTime,
      allTimes,
      dialog,
      month,
    }
  },
  methods: {
    loadOfflineMAR() {
      if ('serviceWorker' in navigator) {
        const userId = localStorage.getItem('userId')
        const siteId = localStorage.getItem('siteId')

        const swPath = `/service-worker.js?medTime=${this.medTime}&userId=${userId}&siteId=${siteId}&month=${this.month}`
        window.addEventListener('activate', navigator.serviceWorker.register(swPath))

        this.fetchMARWeek(this.medTime, this.month)
        this.$watch('marResponse', () => {
          this.updateDialog()
        })
      }
    },
    logData() {
      const messageChannel = new MessageChannel()
      navigator.serviceWorker.controller.postMessage(
        {
          type: 'INIT_PORT',
        },
        [messageChannel.port2],
      )

      messageChannel.port1.onmessage = event => {
        // Print the result
        // console.log(event.data.payload)
      }
      navigator.serviceWorker.controller.postMessage({
        type: 'INCREASE_COUNT',
      })
    },
    updateTime(medTime) {
      if (medTime == null) {
        this.medTime = null
      } else {
        this.medTime = medTime.time
      }

      // console.log(this.medTime)
    },
    updateDialog() {
      // console.log(this.dialog)
      this.dialog = !this.dialog
    },
  },
}
</script>
