<template>
  <div>
    <div
      v-if="dialog"
    >
      <mar-distribution
        :mar-dist="selectedDistribution"
        :mar-order="selectedItem"
        :mar-data="selected[0]"
        :type="distType"
        :is-visible="dialog"
        @update-dialog="updateDialog"
      >
      </mar-distribution>
    </div>

    <v-dialog
      v-model="isVisible"
      persistent
      max-width="85%"
    >
      <v-card>
        <v-row>
          <v-col
            lg="6"
            md="6"
            sm="12"
            xs="12"
          >
            <v-container
              fluid
            >
              <v-card
                outlined
                elevated
              >
                <v-text-field
                  v-model="searchQuery"
                  clearable
                  outlined
                  label="Search Patients"
                ></v-text-field>
                <v-row>
                  <v-col
                    col="12"
                  >
                    <!-- Table Listing All Patients with Medications to Distribute -->
                    <v-data-table
                      :headers="marTableHeaders"
                      :items="marData"
                      :group-by="group"
                      :sort-by="sort"
                      :items-per-page="5"
                      :value="selected"
                      item-key="patientId"
                      show-group-by
                      single-select
                      :search="searchQuery"
                      @click:row="(item) => selectPatient([item])"
                    >
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-col>
          <!-- Card Displaying Patient information -->
          <v-col
            v-for="(patient) in selected"
            :key="patient.jacketId"
            lg="6"
            md="6"
            sm="12"
            xs="12"
          >
            <v-container
              fluid
            >
              <v-card
                outlined
                elevated
              >
                <v-row>
                  <v-col
                    col="12"
                    lg="6"
                  >
                    <!-- Patient Image WIP **** Needs Linking to Actual Patient Image -->
                    <v-img
                      src="@/assets/images/avatars/genericMale.png"
                      max-height="100%"
                      max-width="80%"
                    >
                    </v-img>
                    <!-- Chips for Allergies or Alerts -->
                    <v-col>
                      <v-row>
                        <v-chip
                          v-for="(alert, index) in patient.patientAlerts"
                          :key="index"
                          outlined
                          color="error"
                        >
                          {{ alert }}
                        </v-chip>
                      </v-row>

                      <v-row>
                        <v-chip
                          v-for="(allergy, index) in patient.patientAllergies"
                          :key="index"
                          outlined
                          color="secondary"
                        >
                          {{ allergy }}
                        </v-chip>
                      </v-row>
                    </v-col>
                  </v-col>
                  <!-- Patient Demographics -->
                  <v-col
                    col="12"
                    lg="6"
                  >
                    <v-list>
                      <v-list-item-content><strong>Jacket ID: </strong>{{ patient.jacketId }}</v-list-item-content>
                      <v-list-item-content><strong>Booking ID: </strong>{{ patient.bookingId }}</v-list-item-content>
                      <v-list-item-content><strong>First Name: </strong>{{ patient.firstName }}</v-list-item-content>
                      <v-list-item-content><strong>Last Name: </strong>{{ patient.lastName }}</v-list-item-content>
                      <v-list-item-content><strong>Location: </strong>{{ patient.pod }} {{ patient.cell }} {{ patient.bed }}</v-list-item-content>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>
        <!-- Medication Distribution Calendar -->

        <v-card
          outlined
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-sheet>
                <v-calendar
                  ref="calendar"
                  color="primary"
                  :type="calendarType"
                  :weekdays="weekDays"
                  :events="events"
                  :event-more="false"
                  event-overlap-mode="column"
                  :first-interval="timeOrder() - 1"
                  interval-minutes="60"
                  :interval-count="timeOrder() == 0 ? 24 : 2"
                >
                  <template v-slot:event="{ event }">
                    <div>
                      <!-- An Individual Distribution, Script or Treatment -->
                      <p
                        v-if="medTime !== null"
                        @click="selectDistribution(event)"
                      >
                        {{ event.name }}
                      </p>
                      <p
                        v-else
                      >
                        {{ event.name }}
                      </p>
                    </div>
                  </template>
                </v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card>

        <v-spacer></v-spacer>
        <!-- Close the MAR or Force a Sync -->

        <v-toolbar>
          <v-col class="text-right">
            <v-btn
              color="error darken-1"
              type="reset"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary darken-1"
              text
              @click="syncMAR()"
            >
              Sync
            </v-btn>
          </v-col>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import marService from '@/services/MARService'
import useMARService from '@/store/useMARService'
import moment from 'moment'
import marDistribution from './MARDistribution.vue'

export default {
  components: {
    marDistribution,
  },
  props: {
    marData: {
      type: Array,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    medTime: {
      type: String,
      default: null,
      required: false,
    },
    month: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
      events: [],
      weekDays: [],
    }
  },
  setup(props, { emit }) {
    const MAR_APP = 'app-mar'

    // Register module
    if (!store.hasModule(MAR_APP)) {
      store.registerModule(MAR_APP, marService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MAR_APP)) store.unregisterModule(MAR_APP)
    })

    const { marTableHeaders, fetchMARWeek, marResponse } = useMARService()

    const closeForm = () => {
      emit('update-dialog')
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const today = moment(new Date())

    const marDistributionDTO = ref(null)
    const selectedDistribution = ref(null)
    const selectedItem = ref(null)
    const group = ref('pod')
    const sort = ref('pod')
    const dialog = ref(false)
    const distType = ref('script')
    const calendarType = props.month ? 'month' : 'week'
    const searchQuery = ref(null)

    const timeOrder = () => {
      if (props.medTime == null) {
        return 0
      }

      return props.medTime.split('0')[0]
    }

    return {
      closeForm,
      refreshParent,

      marDistributionDTO,
      selectedDistribution,
      group,
      sort,
      dialog,
      timeOrder,

      today,
      distType,

      marTableHeaders,
      fetchMARWeek,
      marResponse,
      selectedItem,
      calendarType,
      searchQuery,
    }
  },
  mounted() {
    // console.log(this.marData)

    this.selected = [this.marData[0]]
    this.parseEvents()
    this.getWeekDays()
  },
  methods: {
    syncMAR() {
      this.fetchMARWeek(this.medTime, this.month)
      this.marData = this.marResponse
    },
    selectPatient(row) {
      this.selected = row
      this.parseEvents()
    },
    selectDistribution(event) {
      this.selectedDistribution = null
      if (
        this.checkAge(event) >= -120 &&
        this.checkAge(event) <= 240 &&
        event.isActive &&
        !event.distributed &&
        event.refusalReason == null
      ) {
        this.selectedDistribution = event
        this.parseEvents()
        this.updateDialog()
      }
    },
    checkAge(event) {
      const date = moment(event.distributionDate)
      const difference = this.today.diff(moment(date), 'minutes')

      return difference
    },
    parseEvents() {
      let count = 0
      this.events = []
      this.selectedItem = null

      this.selected[0].patientTreatments.forEach(treatment => {
        treatment.marDistributions.forEach(event => {
          this.events[count] = event
          count += 1
        })
        if (this.selectedDistribution != null) {
          if (this.selectedDistribution.patientTreatmentId === treatment.id) {
            this.selectedItem = treatment
            this.distType = 'treatment'
          }
        }
      })
      this.selected[0].patientScripts.forEach(script => {
        script.marDistributions.forEach(event => {
          this.events[count] = event
          count += 1
        })
        if (this.selectedDistribution != null) {
          if (this.selectedDistribution.patientScriptId === script.id) {
            this.selectedItem = script
            this.distType = 'script'
          }
        }
      })
    },
    updateDialog() {
      console.log('dialog on MAR View', this.dialog)
      this.dialog = !this.dialog
    },
    getWeekDays() {
      const dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat']
      const dateObj = new Date()
      const dayName = dayNames[dateObj.getDay()]

      switch (dayName) {
        case 'Sun':
          this.weekDays = [4, 5, 6, 0, 1, 2, 3]
          break
        case 'Mon':
          this.weekDays = [5, 6, 0, 1, 2, 3, 4]
          break
        case 'Tues':
          this.weekDays = [6, 0, 1, 2, 3, 4, 5]
          break
        case 'Wed':
          this.weekDays = [0, 1, 2, 3, 4, 5, 6]
          break
        case 'Thu':
          this.weekDays = [1, 2, 3, 4, 5, 6, 0]
          break
        case 'Fri':
          this.weekDays = [2, 3, 4, 5, 6, 0, 1]
          break
        case 'Sat':
          this.weekDays = [3, 4, 5, 6, 0, 1, 2]
          break

        default:
          this.weekDays = [0, 1, 2, 3, 4, 5, 6]
      }
    },
  },
}
</script>
