import ApiConfig from './ApiConfig'
import { MARClient_V1 } from './generated/api.generated.v1'

import variables from '@/variables'

export default {
  namespaced: true,
  actions: {
    // SESSION

    getMARData(context, payload) {
      const api = new MARClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getMARData(payload.medTime, payload.userId, payload.siteId, payload.month)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => resolve())
      })
    },

    getMARWeek(context, payload) {
      const api = new MARClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getMARWeek(payload.medTime, payload.month)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => resolve())
      })
    },

    getMARUser(context, payload) {
      const api = new MARClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .getMARUser(payload.userId)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => resolve())
      })
    },

    postMARDistribution(context, payload) {
      const api = new MARClient_V1(new ApiConfig(), variables.INTERNAL_API)

      return new Promise((resolve, reject) => {
        api
          .postMARDistribution(payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
          .finally(() => resolve())
      })
    },
  },
}
