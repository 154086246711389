<template>
  <v-dialog
    v-model="isVisible"
    max-width="75%"
    persistent
  >
    <v-card
      ref="form"
    >
      <v-card-title>
        Medical Administration - {{ type }}
        <v-spacer></v-spacer>
        {{ marDist.name }}
      </v-card-title>

      <!-- Patient Demographics -->
      <v-container>
        <v-card
          outlined
        >
          <v-row>
            <v-col
              cols="6"
            >
              <v-container>
                <v-list dense>
                  <v-list-item><strong>Med Pass Date and Time: </strong>{{ marDist.distributionDate }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Directions: </strong> {{ marOrder.directions }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Distribution Type: </strong> {{ marOrder.distributionType }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Prescriber: </strong> {{ marOrder.prescriberName }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Start Date: </strong> {{ marOrder.distributionSchedule.startDate.format('MMM DD YYYY') }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Stop Date: </strong> {{ marOrder.distributionSchedule.stopDate.format('MMM DD YYYY') }}</v-list-item>

                  <v-list-item
                    v-if="type == 'script'"
                  >
                    <strong v-if="marOrder.slidingScale"> Sliding Scale: </strong>
                    <v-col
                      cols="6"
                    >
                      <v-text-field
                        v-model="marDist.quantityDistributed"
                        label="Quantity Distributed"
                        outlined
                        filled
                        dense
                        :value="marDist.quantityDistributed"
                        :readonly="next"
                        :suffix="marOrder.giveUnit"
                        @change="updateQuantityDistributed"
                      >
                      </v-text-field>
                      <v-text-field
                        v-model="marDist.correctedCount"
                        label="Quantity Remaining"
                        outlined
                        filled
                        dense
                        :value="marDist.correctedCount"
                        :readonly="next"
                        :suffix="marOrder.giveUnit.split('/')[0]"
                        @change="updateQuantityRemaining"
                      >
                      </v-text-field>
                      <v-text-field
                        v-if="showCountNote"
                        v-model="marDist.correctedCountNote"
                        label="Corrected Count Note"
                        outlined
                        filled
                        dense
                        :value="marDist.correctedCountNote"
                        :readonly="next"
                        @change="updateCorrectedCountNote"
                      >
                      </v-text-field>
                    </v-col>
                  </v-list-item>
                </v-list>
              </v-container>
            </v-col>
            <v-col
              v-if="type == 'script'"
            >
              <v-container>
                <v-list dense>
                  <v-list-item><strong>Drug Name: </strong> {{ marOrder.drugName }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Quantity To Be Given: </strong> {{ marOrder.giveQuantity }} {{ marOrder.giveUnit }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Drug Strength: </strong> {{ marOrder.drugStrength }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Route: </strong> {{ marOrder.drugRoute }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Form: </strong> {{ marOrder.drugForm }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Refills Available: </strong> {{ marOrder.refills }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Remaining Quantity: </strong> {{ marOrder.remainingQuantity }}</v-list-item>
                  <v-spacer></v-spacer>
                  <v-list-item><strong>Sliding Scale: </strong> {{ marOrder.slidingScale }}</v-list-item>
                </v-list>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <!-- Distribution Completion -->

      <v-container
        v-if="complete"
      >
        <v-row
          v-if="marOrder.formStyle.formName != null"
        >
          <v-col>
            <div>
              <v-card
                outlined
              >
                <form-render
                  :form-data="marOrder.formStyle"
                  :form-input-data="formInput"
                  :is-edit="isEdit"
                  :toolbar="isEdit"
                  @update-dialog="updateSave()"
                  @refetch-data="submitDistribution"
                ></form-render>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <div
          v-else
          @load="updateSave"
        ></div>

        <v-row>
          <v-col
            cols="6"
          >
            <v-card>
              <v-list>
                <v-list-item><strong>Administered By: </strong> {{ userName }}</v-list-item>
                <v-spacer></v-spacer>
              </v-list>

              <v-textarea
                outlined
                :value="additionalNotes"
                name="Notes"
                label="Notes"
                readonly
                rows="2"
              >
              </v-textarea>
              <v-textarea
                v-model="note"
                filled
                name="Additional Notes"
                label="Additional Notes"
                rows="2"
              ></v-textarea>
              <v-toolbar>
                <v-col class="text-right">
                  <v-btn
                    color="success darken-1"
                    type="submit"
                    text
                    @click="addNote()"
                  >
                    Add Note
                  </v-btn>
                  <v-btn
                    color="error darken-1"
                    type="reset"
                    text
                    @click="note = null"
                  >
                    Clear
                  </v-btn>
                </v-col>
              </v-toolbar>
            </v-card>
          </v-col>
          <v-col v-if="type == 'script'">
            <v-card>
              <v-data-table
                :headers="marHeaders"
                :items="events"
                :items-per-page="3"
                item-key="id"
                show-select
                multiple
              >
                <template #[`item.distributionType`]="{item}">
                  <v-chip
                    v-if="item.distributionType == 'PRN'"
                    color="warning"
                  >
                    {{ item.distributionType }}
                  </v-chip>
                  <v-chip v-else>
                    {{ item.distributionType }}
                  </v-chip>
                </template>
              </v-data-table>
              <v-toolbar>
                <v-col class="text-right">
                  <v-btn
                    color="success darken-1"
                    type="submit"
                    text
                    @click="addNote()"
                  >
                    Distribute Selected
                  </v-btn>
                </v-col>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- Distribution Refusal/Incompletion -->
      <v-container
        v-if="refuse"
      >
        <v-row>
          <v-col>
            <v-radio-group
              v-model="refusalReason"
              row
              :rules="[required]"
            >
              <v-radio
                label="Refused by Patient"
                value="Refused by Patient"
                default
              ></v-radio>
              <v-radio
                label="Patient not in Cell"
                value="Patient not in Cell"
              ></v-radio>
              <v-radio
                label="Medication Held"
                value="Medication Held"
              ></v-radio>
              <v-radio
                label="Medication Out of Stock"
                value="Medication Out of Stock"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="refusalNote"
              label="Refusal Note"
              outlined
              filled
              dense
              :rules="[required]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="refuse"
        >
          <v-col>
            <div>
              <v-container>
                <form-render
                  :form-data="marDist.refusalForm"
                  :form-input-data="formInput"
                  :is-edit="isEdit"
                  :toolbar="isEdit"
                  @update-dialog="submitDistribution"
                ></form-render>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- Complete is only Shown at Patient Demographics section -->
      <v-toolbar>
        <div
          v-if="!next"
        >
          <v-col class="text-left">
            <v-btn
              color="success darken-1"
              type="submit"
              outlined
              text
              @click="completeDist()"
            >
              Complete Distribution
            </v-btn>
            <v-btn
              color="error darken-1"
              type="submit"
              outlined
              text
              @click="refuseDist()"
            >
              Not Complete
            </v-btn>
          </v-col>
        </div>

        <!-- Close only available before refusal or completion selected -->
        <v-col class="text-right">
          <v-btn
            v-if="!next"
            color="error darken-1"
            type="reset"
            text
            @click="closeForm()"
          >
            Close
          </v-btn>
          <v-btn
            v-if="showSave"
            color="primary darken-1"
            type="submit"
            text
            @click="submitDistribution('save')"
          >
            Save
          </v-btn>
        </v-col>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import moment from 'moment'
import store from '@/store'
import marService from '@/services/MARService'
import useMARService from '@/store/useMARService'
import FormRender from '@/apps/form/FormRender.vue'
import { required } from '@core/utils/validation'

export default {
  components: { FormRender },
  props: {
    marOrder: {
      type: Object,
      required: true,
    },
    marData: {
      type: Object,
      required: true,
    },
    marDist: {
      type: Object,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
      default: 'script',
    },
  },
  data() {
    return {
      additionalNotes: [],
      marHeaders: [
        { text: 'TYPE', value: 'distributionType' },
        { text: 'NAME', value: 'name' },
      ],
      late: false,
      events: [],
    }
  },
  setup(props, { emit }) {
    console.log('MAR Dist', props.isVisible)
    const MAR_APP = 'app-mar'

    // Register module
    if (!store.hasModule(MAR_APP)) {
      store.registerModule(MAR_APP, marService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MAR_APP)) store.unregisterModule(MAR_APP)
    })

    const { postMARDistribution } = useMARService()

    const next = ref(false)
    const isEdit = ref(true)
    const complete = ref(false)
    const showSave = ref(false)
    const showCountNote = ref(false)
    const refuse = ref(false)
    const note = ref(null)
    const today = ref(null)

    const userName = localStorage.getItem('displayName')

    const formInput = ref({})
    const refusalReason = ref('Refused by Patient')
    const refusalNote = ref(null)
    const form = ref(null)

    const closeForm = () => {
      emit('update-dialog')
    }
    const validate = marDist => {
      form.value.validate(marDist)
    }

    return {
      closeForm,
      next,
      complete,
      refuse,
      note,
      userName,
      today,
      formInput,
      refusalReason,
      refusalNote,
      showSave,
      showCountNote,
      form,
      isEdit,

      validate,

      postMARDistribution,

      required,
    }
  },
  mounted() {
    if (this.marOrder.giveQuantity != null) {
      this.marDist.quantityDistributed = this.marOrder.giveQuantity
      this.marDist.correctedCount = this.marOrder.remainingQuantity - this.marDist.quantityDistributed
    } else {
      this.marDist.quantityDistributed = 0
      this.marDist.correctedCount = 0
      this.marDist.correctedCountNote = null
    }

    this.parseEvents()
  },
  methods: {
    // Complete set to true, updating Next
    completeDist() {
      this.next = !this.next
      this.complete = true
      this.refuse = false
      this.formInput = { patient: this.marData.patientId, marDistributionId: this.marDist.id }
      console.log('Complete Dist', this.formInput, this.complete, this.refuse, this.marOrder, this.isVisible)
      this.parseEvents()
      if (this.marOrder.formStyle.formName == null) {
        this.updateSave()
      }
    },

    // Refuse set to true, updating Next
    refuseDist() {
      this.next = !this.next
      this.refuse = true
      this.complete = false
      this.formInput = { patient: this.marData.patientId, marDistributionId: this.marDist.id }
      console.log('Not Complete Dist', this.formInput)
    },
    submitDistribution(result) {
      if (result === 'save') {
        if (this.complete) {
          this.marDist.additionalNotes = this.additionalNotes
          this.marDist.distributed = true
          this.marDist.color = 'primary'
          console.log('submit', this.marDist)
        }
        if (this.refuse) {
          this.marDist.color = '#00BCD4'
          this.marDist.refusalReason = this.refusalReason
          this.marDist.quantityDistributed = 0
          this.marDist.refusalNote = this.refusalNote
          console.log('submit', this.marDist)
        }
        this.marDist.modifiedDate = moment(Date.now())
        this.postMARDistribution(this.marDist)
      }
      this.closeForm()
    },
    updateQuantityDistributed(value) {
      console.log('QT', value)
      this.marDist.quantityDistributed = value
    },
    updateQuantityRemaining(value) {
      console.log('QR', value)
      this.showCountNote = true
      this.marDist.correctedCount = value
    },
    updateCorrectedCountNote(value) {
      console.log('Note', value)
      this.marDist.correctedCountNote = value
    },
    updateSave() {
      this.showSave = true
      console.log('Save', this.showSave)
    },

    // Adding Notes before completing distribution
    addNote() {
      console.log(this.note)
      if (this.note != null) {
        this.additionalNotes.push(this.note)
        this.note = null
      }
    },
    checkAge() {
      const date = moment(this.marDist.distributionDate)
      const difference = this.today.diff(moment(date), 'hours')
      console.log('age', this.today.format('yyyy-MM-DD'), difference, this.marOrder, id)

      return difference
    },
    addEvent(event, id) {
      let count = 0
      this.today = moment(new Date())

      if (!event.distributed && event.start === this.today.format('yyyy-MM-DD') && this.marOrder.id !== id) {
        if (this.checkAge(event) >= -120 && this.checkAge(event) <= 240) {
          console.log(event)
          this.late = true
          this.events[count] = event
          count += 1
        }
      }
    },
    parseEvents() {
      this.events = []
      if (this.type === 'script') {
        this.marData.patientScripts.forEach(script => {
          script.marDistributions.forEach(event => {
            this.addEvent(event, script.id)
          })
        })
      }
      if (this.type === 'treatment') {
        this.marData.patientTreatments.forEach(treatment => {
          treatment.marDistributions.forEach(event => {
            this.addEvent(event, treatment.id)
          })
        })
      }
    },
  },
}
</script>
